import React, { useState } from "react";
import { 
  Box, 
  Grid, 
  Container, 
  Typography, 
  Button, 
  // Link, 
  TextField, 
  // FormControl, 
  // Input,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { subscribe } from "./emailAlert";

const GetEmailAlertsButton = () => {
  const [showEmailField, setShowEmailField] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState(null);

  const subscribeToEmailAlert = () => {
    console.log(email)
    if (email !== null) {
      subscribe(email).then(() => {
        setFeedback("You are now Subscribed!");
        setTimeout(() => setFeedback(""), 2500);
      })
    } else {
      setFeedback("Please Add your email");
    }
  }

  return (
    <>
      <Box hidden={!showEmailField} style={{textAlign: "center"}}>
        <Box>
          <TextField 
            size="small" 
            id="standard-basic" 
            variant="outlined" 
            label="Email" 
            placeholder="e.g. yourname@gmail.com"
            onChange={(e) => setEmail(e.target.value)}
            // value={email}
          />
          <Button
            variant="contained" 
            size="large"
            type="submit"
            style={{backgroundColor: "#223659", color:"white", marginLeft: "1em" }}
            onClick={subscribeToEmailAlert}
          >
            Subscribe
          </Button>
        </Box>
        <Box 
          hidden={feedback === ""} 
          style={{marginTop: "1em"}}
        >
            {feedback}
        </Box>
      </Box>
      <Box hidden={showEmailField}>
        <Button 
          variant="contained" 
          size="large"
          style={{backgroundColor: "#223659", color:"white"}}
          onClick={() => setShowEmailField(true)}
        >
          Get Email Alerts
        </Button>
      </Box>
    </>
  );
}

const HeroBanner = () => {
  const theme = useTheme();

  return (
    <section className="wrapper bg-soft-primary">
      <div className="container pt-10 pb-12 pt-md-14 pb-md-17">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 mt-lg-n2 text-center text-lg-start order-2 order-lg-0" data-cues="slideInDown" data-group="page-title" data-delay="600">
            <h1 className="display-1 mb-5 mx-md-10 mx-lg-0">Leverage the power of <span className="highlight">unbiased</span> <br/>credit analytics<br />for <span className="highlight">alpha generation</span>
            
            </h1>
            <p className="lead fs-lg mb-7 text-silver">Explainable, fundamentals-driven, AI-based insights on over 9,000 corporate bonds for managing risk and allocating capital more effectively.</p>
            <div className="d-flex justify-content-center justify-content-lg-start" data-cues="slideInDown" data-group="page-title-buttons" data-delay="900">
              <span><a className="btn btn-lg btn-primary rounded me-2"target="_blank" href={process.env.GATSBY_FREE_TRIAL_FORM_URL}>Start My Free Trial</a></span>
              <span><a className="btn btn-lg btn-white-transparent rounded" href="https://www.youtube.com/@migrationsml4087">Watch Demos</a></span>
            </div>
          </div>
          <div className="col-lg-7">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/tazZA28-8fU?modestbranding=1" title="Introduction to Migrations.ml" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
